import { Injectable } from '@angular/core';
import { UntilDestroy } from '@ngneat/until-destroy';
import { Actions, ofType } from '@ngrx/effects';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import { cloneDeep } from 'lodash';
import { BehaviorSubject, Observable } from 'rxjs';
import { loadAllClaimsSuccess } from '../root-store/user-data/user-data.actions';
import { AppRole } from '../shared/models/enums/app-role.enum';
import { SecurityService } from '../shared/services/security.service';
import { NavItem } from '../shared/widgets/menu-list-item/nav-item';
import { environment } from 'project/src/environments/environment';

@Injectable({ providedIn: 'root' })
@UntilDestroy()
export class MainMenuItens {
  private items$: BehaviorSubject<NavItem[]>;

  constructor(
    private securityService: SecurityService,
    public oidcSecurityService: OidcSecurityService,
    updates$: Actions
  ) {
    this.items$ = new BehaviorSubject<NavItem[]>(this.getFiltered());

    // this.authenticationService.currentUser
    //   .pipe(untilDestroyed(this))
    //   .subscribe(() => this.items$.next(this.getFiltered()));

    updates$.pipe(ofType(loadAllClaimsSuccess)).subscribe(() => {
      this.items$.next(this.getFiltered());
    });
  }

  public getNavItems(): Observable<NavItem[]> {
    return this.items$.asObservable();
  }

  private getFiltered() {
    const items = cloneDeep(navItems);
    const result = this.filterForClaims(items);

    return result;
  }

  private filterForClaims(items: NavItem[]) {
    const filtered = items.filter((_) => _.claims == null || this.securityService.hasClaims(_.claims));

    filtered.forEach((i) => {
      if (i.children != null) {
        i.children = this.filterForClaims(i.children);
      }
    });

    return filtered;
  }
}

const navItems: NavItem[] = [
  // {
  //   displayName: 'Menu.scheduller.Title',
  //   iconName: 'map',
  //   route: '/authorised/map/map',
  //   claims: ['AccessToOrdersCalendar'],
  // },
  {
    displayName: 'Menu.Orders.Title',
    iconName: 'alt_route',
    route: '/authorised/orders/catalog',
    claims: ['AccessToOrdersCalendar'],
  },
  {
    displayName: 'Menu.deliveryCalendar.Title',
    iconName: 'local_shipping',
    route: '/authorised/delivery-calendar',
    claims: ['AccessToDeliveryCalendar'],
  },
  {
    displayName: 'Menu.pumpScheduller.Title',
    iconName: 'insert_chart_outlined',
    route: '/authorised/pump-scheduler',
    claims: ['AccessToPumpLoadCalendar'],
  },
  {
    displayName: 'Menu.OrderManagement',
    iconName: 'book_icon',
    children: [
      {
        displayName: 'Menu.scheduller.Title',
        iconName: 'calendar_today',
        route: '/authorised/calendar',
        claims: ['AccessToOrdersCalendar'],
      },
      {
        displayName: 'Menu.OrderStatuses.Title',
        iconName: 'alt_route',
        route: '/authorised/order-statuses/catalog',
        claims: ['AccessToOrdersCalendar'],
      },
      {
        displayName: 'Menu.nodesScheduller.Title',
        iconName: 'insert_chart_outlined',
        route: '/authorised/nodes-scheduler',
        claims: ['AccessToPumpLoadCalendar'],
      },
    ],
  },
  {
    displayName: 'Menu.DeliveryStatuses.Title',
    iconName: 'alt_route',
    route: '/authorised/delivery-statuses/catalog',
    claims: ['AccessToOrderAndDeliveryStatuses'],
  },
  // {
  //   displayName: 'Menu.Offers.Title',
  //   iconName: 'drive_file_rename_outline',
  //   route: '/authorised/offers/catalog',
  //   claims: ['AccessToOffers'],
  // },
  // {
  //   displayName: 'Menu.Settlements.Title',
  //   iconName: 'account_balance_wallet',
  //   route: '/authorised/orders/settlements',
  //   claims: ['AccessToSettlements'],
  // },

  {
    displayName: 'Menu.wzDocuments.Title',
    iconName: 'text_snippet',
    route: '/authorised/wz-documents/catalog',
    claims: ['AccessToWZDocuments'],
  },

  // {
  //   displayName: 'Menu.ordersFullfilment.Title',
  //   iconName: 'alt_route',
  //   route: '/authorised/orders-fullfilment',
  //   claims: ['AccessToOrderFulfillmentCalendar'],
  // },

  //order-statuses
  // {
  //   displayName: 'Menu.IncomingCorrespondence.Title',
  //   iconName: 'arrow_forward',
  //   route: '/authorised/incoming-messages/catalog',
  // },
  // {
  //   displayName: 'Menu.OutgoingCorrespondence.Title',
  //   iconName: 'arrow_back',
  //   route: '/authorised/outgoing-messages/catalog',
  // },
  // {
  //   displayName: 'Menu.Documents.Title',
  //   iconName: 'receipt',
  //   route: '/authorised/other-documents/catalog',
  // },
  // {
  //   displayName: 'Menu.Workflow.Title',
  //   iconName: 'receipt_long',
  //   link: 'https://wydawnisctwoliterackie.sharepoint.com/sites/Sekretariat/',
  // },
  {
    displayName: 'Menu.Dictionaries.Title',
    iconName: 'bookmark_border',
    children: [
      // {
      //   displayName: 'Menu.Authors',
      //   iconName: 'view_list',
      //   route: '/authorised/dictionaries/authors',
      // },
      // {
      //   displayName: 'Menu.Departments',
      //   iconName: 'view_list',
      //   route: '/authorised/dictionaries/departments',
      // },
      // {
      //   displayName: 'Menu.Employees',
      //   iconName: 'view_list',
      //   route: '/authorised/dictionaries/employees',
      // },
      // {
      //   displayName: 'Menu.Clients',
      //   iconName: 'view_list',
      //   route: '/authorised/dictionaries/clients',
      // },


      {
        displayName: 'Menu.Items.Title',
        iconName: 'local_grocery_store',
        route: '/authorised/items/catalog',
        claims: ['AccessToProducts'],
      },
      {
        displayName: 'Menu.Customers.Title',
        iconName: 'how_to_reg',
        route: '/authorised/customers/catalog',
        claims: ['AccessToCustomers'],
      },

      {
        displayName: 'Menu.Employees.Title',
        iconName: 'person',
        route: '/authorised/employees/catalog',
        claims: ['AccessToEmployees'],
      },
      {
        displayName: 'Menu.Drivers.Title',
        iconName: 'person',
        route: '/authorised/drivers/catalog',
        claims: ['AccessToDrivers'],
      },
      {
        displayName: 'Menu.Cars.Title',
        iconName: 'directions_bus',
        route: '/authorised/cars/catalog',
        claims: ['AccessToCars'],
      },
      {
        displayName: 'Menu.ExternalUsers.Title',
        iconName: 'person',
        route: '/authorised/external-users/catalog',
        claims: ['AccessToExternalUsers'],
      },
      {
        displayName: 'Menu.SpecialAdditives.Title',
        iconName: 'construction',
        route: '/authorised/special-additives/catalog',
        claims: ['AccessToSpecialAdditives'],
      },
      {
        displayName: 'Menu.StructuralElements.Title',
        iconName: 'construction',
        route: '/authorised/structural-elements/catalog',
        claims: ['AccessToStructuralElements'],
      },
    ],
  },
  // {
  //   displayName: 'Menu.Reports',
  //   iconName: 'error',
  //   claims: ['AccessToReports'],
  //   link: environment.reportsUrl,
  // },

  // {
  //   displayName: 'Menu.RecipeManagement',
  //   iconName: ' bookmark_icon',
  //   claims: [],
  //   children: [
  //     {
  //       displayName: 'Menu.recipe.Title',
  //       iconName: 'menu_book',
  //       route: '/authorised/recipe',
  //       claims: ['AccessToRecipes'],
  //     },
  //     {
  //       displayName: 'Menu.RecipeCosts',
  //       iconName: 'list_view',
  //       route: '/authorised/recipeCosts/catalog',
  //       claims: ['AccessToRecipesCostsCatalog'],
  //     },
  //     {
  //       displayName: 'Menu.Ingredients',
  //       iconName: 'view_list',
  //       route: '/authorised/ingredients/catalog',
  //       claims: ['AccessToRecipesCosts'],
  //     },
  //     {
  //       displayName: 'Menu.Transactions.Title',
  //       iconName: 'compare_arrows',
  //       route: '/authorised/transactions/catalog',
  //       claims: ['AccessToRecipesCosts'],
  //     },
  //   ],
  // },
];
